import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import { List } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';




class MyCalendarIndex extends Component {

  updateHash(val) {
    window.location.hash = val
  }


  contentUpdate(data) {
    this.props.onContentChange(data)
  }

  render() {

    return (

      <Paper>
        <div style={{ width: '100%', padding: '15px' }}>
          <b>Table of Contents</b>
          <div>
            <List>


              <ListItem button onClick={() => { this.contentUpdate('q1'); this.updateHash('q1') }}>
                <ListItemText primary="1.How to book Timesheets?" />
              </ListItem>
              <ListItem button onClick={() => { this.contentUpdate('q2'); this.updateHash('q2') }}>
                <ListItemText primary="2.How to add a Project/Overhead to the Timesheet?" />
              </ListItem>
              <ListItem button onClick={() => { this.contentUpdate('q3'); this.updateHash('q3') }}>
                <ListItemText primary="3.Additional Features of Timesheet?" />
              </ListItem>
              <ListItem button onClick={() => { this.contentUpdate('q4'); this.updateHash('q4') }}>
                <ListItemText primary="4.How to request Additional hours to the Tasks?" />
              </ListItem>
              <ListItem button onClick={() => { this.contentUpdate('q5'); this.updateHash('q5') }} >
                <ListItemText primary="5.How to plan Time for the added Task?" />
              </ListItem>
              <ListItem button onClick={() => { this.contentUpdate('q6'); this.updateHash('q6') }}>
                <ListItemText primary="6.How to add Comments to the Tasks?" />
              </ListItem>
            </List>
          </div>
        </div>
      </Paper>




    )
  }
}

export default MyCalendarIndex
