import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Search from './FAQ/Search'
import Main from './FAQ/Main'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { getCookie } from './CommonUtils/CookieHelper';
import PaRaMForms from './PaRaMForms/PaRaMForms';
import FormQuide from './PaRaMForms/FormQuide';

const bbColorTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#007681',
    },
    secondary: {
      main: '#EEBE86',
    },
  }
})

class App extends Component {

  componentDidMount() {
    if (!getCookie("bb_ts_user") && window.location.host === "faqs.bbparam.com") {
      window.location.href = "https://bbparam.com"
    }
  }

  render() {
    return (
      <ThemeProvider theme={bbColorTheme}>
        <Router>
          <Switch>
            <Route path='/' exact component={Search} />
            <Route path='/paramfaqs/:category' exact component={Main} />
            <Route path='/paramformsfaqs' exact component={PaRaMForms} />
            <Route path='/raisearequest' exact component={FormQuide} />
          </Switch>
        </Router>
      </ThemeProvider>
    );
  }
}
export default App;