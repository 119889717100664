import React, { Component } from 'react';
// import { GridList, GridListTile } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { getCookie } from '../CommonUtils/CookieHelper';
import HelpIcon from '@material-ui/icons/Help';

class FormQues extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            search_ques: ""
        }
    }

    componentDidMount() {

        if (getCookie("bb_ts_user") && window.location.host === "faqs.bbparam.com") {
            this.setState({
                user: JSON.parse(getCookie("bb_ts_user"))
            })
        }

        var url = window.location.protocol + '//' + window.location.hostname

        if (!window.location.port || window.location.port === '') {
            url = url + "/searchdata/paramforms.json"

        } else {
            url = url + ":" + window.location.port + "/searchdata/paramforms.json"
        }

        fetch(url)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    data: data
                })
            })
    }

    changeContent = (e, values) => {
        if (values) {
            let data = values.question_no
            if (data === 'q1') {
                this.setState({ search_ques: 'q1' })
                this.myRef1.scrollIntoView({ behavior: "smooth", block: 'center' })
            } else if (data === 'q2') {
                this.setState({ search_ques: 'q2' })
                this.myRef2.scrollIntoView({ behavior: "smooth", block: 'center' })
            } else if (data === 'q3') {
                this.setState({ search_ques: 'q3' })
                this.myRef3.scrollIntoView({ behavior: "smooth", block: 'center' })
            } else if (data === 'q4') {
                this.setState({ search_ques: 'q4' })
                this.myRef4.scrollIntoView({ behavior: "smooth", block: 'center' })
            } else if (data === 'q5') {
                this.setState({ search_ques: 'q5' })
                this.myRef5.scrollIntoView({ behavior: "smooth", block: 'center' })
            } else if (data === 'q6') {
                this.setState({ search_ques: 'q6' })
                this.myRef6.scrollIntoView({ behavior: "smooth", block: 'center' })
            } else if (data === 'q7') {
                this.setState({ search_ques: 'q7' })
                this.myRef7.scrollIntoView({ behavior: "smooth", block: 'center' })
            } else if (data === 'q8') {
                this.setState({ search_ques: 'q8' })
                this.myRef8.scrollIntoView({ behavior: "smooth", block: 'center' })
            } else if (data === 'q9') {
                this.setState({ search_ques: 'q9' })
                this.myRef9.scrollIntoView({ behavior: "smooth", block: 'center' })
            } else if (data === 'q10') {
                this.setState({ search_ques: 'q10' })
                this.myRef10.scrollIntoView({ behavior: "smooth", block: 'center' })
            } else if (data === 'q11') {
                this.setState({ search_ques: 'q11' })
                this.myRef11.scrollIntoView({ behavior: "smooth", block: 'center' })
            } else if (data === 'q12') {
                this.setState({ search_ques: 'q12' })
                this.myRef12.scrollIntoView({ behavior: "smooth", block: 'center' })
            } else if (data === 'q13') {
                this.setState({ search_ques: 'q13' })
                this.myRef13.scrollIntoView({ behavior: "smooth", block: 'center' })
            } else if (data === 'q14') {
                this.setState({ search_ques: 'q14' })
                this.myRef14.scrollIntoView({ behavior: "smooth", block: 'center' })
            } else {
                this.setState({ search_ques: '' })
                // this.myRef1.scrollIntoView({ behavior: "smooth", block: 'center' })
            }
        } else {
            this.setState({ search_ques: '' })
        }
    }
    render() {
        return (
            <div>
                <Box flexDirection="row" maxHeight={300} display="flex" justifyContent="center">
                    <Autocomplete
                        id="combo-box-demo"
                        options={this.state.data}
                        getOptionLabel={(option) => option.title}
                        style={{ width: 500 }}
                        onChange={this.changeContent}
                        renderInput={(params) => <TextField {...params} label="Search questions" variant="outlined" />} />
                </Box>
                <Box display={'flex'} flexDirection={'row'} style={{ height: (window.innerHeight - 140), overflowY: 'scroll', }}>
                    <Grid container >

                        <Grid item xs={12} ref={(ref) => this.myRef1 = ref}>
                            <Box display={'flex'} flexDirection={'column'} m={1} >
                                <h2 style={{ color: this.state.search_ques === 'q1' ? '#00b300' : '' }}>Q1. Where can I find the Forms?</h2>
                                <Box display="flex" flexDirection={'row'} justifyContent="left" ml={'50px'} mt={'5px'}>
                                    <ul>
                                        <li>
                                            All forms are stored on your dashboard under Raise a Request icon
                                        </li>
                                    </ul>
                                    <HelpIcon />
                                </Box>
                            </Box>

                        </Grid>

                        <Grid item xs={12} ref={(ref) => this.myRef2 = ref}>
                            <Box display={'flex'} flexDirection={'column'} m={1}>
                                <h2 style={{ color: this.state.search_ques === 'q2' ? '#00b300' : '' }}>Q2. Who can submit PaRaM Forms?</h2>
                                <Box display="flex" justifyContent="left" ml={'50px'} mt={'5px'}>
                                    <ul>
                                        <li>
                                            Defects and requirements forms can be submitted by all PaRaM Users, however New User Request forms and
                                            setting up a project can only be requested by Engineering Managers, Head of Engineering,
                                            Design delivery Managers and Team Leads.
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} ref={(ref) => this.myRef3 = ref}>
                            <Box display={'flex'} flexDirection={'column'} m={1}>
                                <h2 style={{ color: this.state.search_ques === 'q3' ? '#00b300' : '' }}>Q3. How long does it take to process a Form by administrators after submission?</h2>
                                <Box display="flex" justifyContent="left" ml={'50px'} mt={'5px'}>
                                    <ul>
                                        <li>
                                            Our service level agreement is 2 days to turn around request.
                                        </li>
                                        <li>
                                            Your request will be acknowledged in 5 working days after it’s been reviewed in our PaRaM Governance Board.
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} ref={(ref) => this.myRef4 = ref}>
                            <Box display={'flex'} flexDirection={'column'} m={1}>
                                <h2 style={{ color: this.state.search_ques === 'q4' ? '#00b300' : '' }}>Q4. Where can I view my submitted requests?</h2>
                                <Box display="flex" justifyContent="left" ml={'50px'} mt={'5px'}>
                                    <ul>
                                        <li>
                                            Your pending requests for the defects and requirements forms can be viewed in Help and Support section.
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} ref={(ref) => this.myRef5 = ref}>
                            <Box display={'flex'} flexDirection={'column'} m={1}>
                                <h2 style={{ color: this.state.search_ques === 'q5' ? '#00b300' : '' }}>Q5. Who will approve the Forms?</h2>
                                <Box display="flex" justifyContent="left" ml={'50px'} mt={'5px'}>
                                    <ul>
                                        <li>
                                            The forms will get approved by PaRaM Governance and Process Board.
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} ref={(ref) => this.myRef6 = ref}>
                            <Box display={'flex'} flexDirection={'column'} m={1}>
                                <h2 style={{ color: this.state.search_ques === 'q6' ? '#00b300' : '' }}>Q6. Do I get an email notification?</h2>
                                <Box display="flex" justifyContent="left" ml={'50px'} mt={'5px'}>
                                    <ul>
                                        <li>
                                            Yes.
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} ref={(ref) => this.myRef7 = ref}>
                            <Box display={'flex'} flexDirection={'column'} m={1}>
                                <h2 style={{ color: this.state.search_ques === 'q7' ? '#00b300' : '' }}>Q7. Can I edit/update my Form after submission?</h2>
                                <Box display="flex" justifyContent="left" ml={'50px'} mt={'5px'}>
                                    <ul>
                                        <li>
                                            Yes, you can edit your form after submission via the Support Manager icon
                                            which can be found on your dashboard from the menu on the left.
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} ref={(ref) => this.myRef8 = ref}>
                            <Box display={'flex'} flexDirection={'column'} m={1}>
                                <h2 style={{ color: this.state.search_ques === 'q8' ? '#00b300' : '' }}>Q8. Can I delete a form or recover a deleted Form?</h2>
                                <Box display="flex" justifyContent="left" ml={'50px'} mt={'5px'}>
                                    <ul>
                                        <li>
                                            Unfortunately, the form cannot be deleted before or after the submission.
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} ref={(ref) => this.myRef9 = ref}>
                            <Box display={'flex'} flexDirection={'column'} m={1}>
                                <h2 style={{ color: this.state.search_ques === 'q9' ? '#00b300' : '' }}>Q9. How many Forms can I create?</h2>
                                <Box display="flex" justifyContent="left" ml={'50px'} mt={'5px'}>
                                    <ul>
                                        <li>
                                            There is no limit.
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} ref={(ref) => this.myRef10 = ref}>
                            <Box display={'flex'} flexDirection={'column'} m={1}>
                                <h2 style={{ color: this.state.search_ques === 'q10' ? '#00b300' : '' }}>Q10. Can I add multiple people on one Form?</h2>
                                <Box display="flex" justifyContent="left" ml={'50px'} mt={'5px'}>
                                    <ul>
                                        <li>
                                            No, you can add only one submission at a time.
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} ref={(ref) => this.myRef11 = ref}>
                            <Box display={'flex'} flexDirection={'column'} m={1}>
                                <h2 style={{ color: this.state.search_ques === 'q11' ? '#00b300' : '' }}>Q11. What file types can you upload on the Form?</h2>
                                <Box display="flex" justifyContent="left" ml={'50px'} mt={'5px'}>
                                    <ul>
                                        <li>
                                            You can upload all file types.
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} ref={(ref) => this.myRef12 = ref}>
                            <Box display={'flex'} flexDirection={'column'} m={1}>
                                <h2 style={{ color: this.state.search_ques === 'q12' ? '#00b300' : '' }}>Q12. What is the difference between Internal Emp ID and R12 Emp Code?</h2>
                                <Box display="flex" justifyContent="left" ml={'50px'} mt={'5px'}>
                                    <ul>
                                        <li>
                                            Internal Emp ID is only used in India and R12 Emp Code is used in UK.
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} ref={(ref) => this.myRef13 = ref}>
                            <Box display={'flex'} flexDirection={'column'} m={1}>
                                <h2 style={{ color: this.state.search_ques === 'q13' ? '#00b300' : '' }}>Q13. What is the character limit for Forms?</h2>
                                <Box display="flex" justifyContent="left" ml={'50px'} mt={'5px'}>
                                    <ul>
                                        <li>
                                            The character limit for comments on the forms will vary from section to section
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} ref={(ref) => this.myRef14 = ref}>
                            <Box display={'flex'} flexDirection={'column'} m={1}>
                                <h2 style={{ color: this.state.search_ques === 'q14' ? '#00b300' : '' }}>Q14. Is there a file number limit in Drag & Drop section?</h2>
                                <Box display="flex" justifyContent="left" ml={'50px'} mt={'5px'}>
                                    <ul>
                                        <li>
                                            The limit of files that you can upload is <b>25MB</b>
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                </Box>

            </div>
        );
    }
}

export default FormQues;