import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import { GridList, GridListTile, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const useStyles = ((theme) => ({
  root: {
    width: '100%',

  },
  root1: {
    minWidth: 275,
    paddingRight: 1150,
    alignItems: 'center',
  },
  paper: {
    height: '100%',
    width: '100%',
    flexShrink: 0,
  },
  title: {
    fontSize: 14
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "black"

  },
}));



class MyCalendar extends Component {

  updateHash(val) {
    window.location.hash = val
  }

  constructor(props) {
    super(props)

    this.state = {

    }
    this.changeContent = this.changeContent.bind(this)
  }


  componentDidMount() {
    // console.log(window.location.hash)
    setTimeout(() => {
      if (window.location.hash === '#q1') {
        this.myRef1.scrollIntoView()
      } else if (window.location.hash === '#q2') {
        this.myRef2.scrollIntoView()
      } else if (window.location.hash === '#q3') {
        this.myRef3.scrollIntoView()
      } else if (window.location.hash === '#q4') {
        this.myRef4.scrollIntoView()
      } else if (window.location.hash === '#q5') {
        this.myRef5.scrollIntoView()
      } else if (window.location.hash === '#q6') {
        this.myRef6.scrollIntoView()
      } else {
        this.myRef.scrollIntoView()
      }
    }, 300);

  }

  changeContent(data) {
    if (data === 'q1') {
      this.myRef1.scrollIntoView()
    } else if (data === 'q2') {
      this.myRef2.scrollIntoView()
    } else if (data === 'q3') {
      this.myRef3.scrollIntoView()
    } else if (data === 'q4') {
      this.myRef4.scrollIntoView()
    } else if (data === 'q5') {
      this.myRef5.scrollIntoView()
    } else if (data === 'q6') {
      this.myRef6.scrollIntoView()
    } else {
      this.myRef.scrollIntoView()
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} ref={(ref) => this.myRef = ref} >
        <Paper >
          <GridList cols={1} cellHeight='auto' >

            <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef1 = ref}>
              <h2 >Q1.How to book Timesheets?</h2>
              <p>To plan your work or just fill in your timesheet, select the My Calendar icon</p>
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T53.png'} alt="" width="350" className="pic" />
              </Box><br />
              <p>on your dashboard page – the below window will open (it will open on the current week if you are filling your timesheet on the Friday of the week you are on).</p>
              <p> for <b>UK Users: </b>Complete each day with 7.5 hours making up your 37.5 hours for the week.</p>
              <p> for <b>IND Users: </b>Complete each day with 8 hours making up your 40 hours for the week.</p>
              <p> for <b>SL Users: </b>Complete each day with 8.5 hours making up your 42.5 hours for the week.</p>
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T54.png'} alt="" width="750" className="pic" />
              </Box><br />
              <p><b>Please note: </b>if you do not fill your timesheet in until Monday the next week you will need to click on the back icon.</p>
            </GridListTile>

            <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef2 = ref}>
              <h2 >Q2.How to add a Project/Overhead to the Timesheet?</h2>
              <p>
                Click on the timesheet in any day you want to add the hours too, the box below will appear, click the icon to select either billable projects,
                non billable projects, or non billable (as per screen shots below)  To select the project you require click on the downward arrow on the first box,
                then select the delivery schedule,WBS & task in the project you require – for the non billable work you will need to select a catergory & task,
                your team will be populated if you are only assigned to one team (if you are assigned to more than 1 team you will need to select your team as well, click add task.
              </p><br />
              <Box display="flex" flexDirection={'row'} justifyContent={'center'}>
                <Box>
                  <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T55.png'} alt="" width="350" className="pic" />
                </Box>
                <Box marginLeft='10px'>
                  <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T56.png'} alt="" width="350" className="pic" />
                </Box>
              </Box><br />
              <p>If you want to add the same activity for Multiple days (or all week)
                click the box next to add for Multiple days (which is located at the bottom of your timesheet)
                select the date on the second calendar & click add tasks. As per the screen shot below </p>
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T57.png'} alt="" width="400" className="pic" />
              </Box><br />
              <p>Once populated your timesheet will look simular to the extract below</p>
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T58.png'} alt="" width="750" className="pic" />
              </Box><br />
              <p>
                To submit the timesheet select the submit timesheet icon which is at the top right hand side of the screen,
                (as Shown in the screen shot above) this will open a summary page of all the activities you have booked your time too,
                Review then click submit timesheet
              </p>
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T59.png'} alt="" width="750" className="pic" />
              </Box><br />
              <p><b>Please note: </b>: If you have not completed your timesheet with all your contracted hours there will
                be an error message appear at the top of the page, (as shown in the screen shot above) to clear this you will
                either need to complete your timesheet with the correct contracted hours or click continue this is located at the
                top right-hand side of the page. This will then allow you to submit your timesheet.
                To identify your timesheet is submitted the tasks in your timesheet change to orange,
                then once it is approved the tasks change to green.
              </p>
              <p><b>Note: </b>Once your timesheet is submitted you are unable to amend it, if you notice it is
                incorrect after this time, please speak with your team lead/line manager or one of the PaRaM team.</p>
            </GridListTile>

            <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef3 = ref}>
              <h2 >Q3.Additional Features of Timesheet?</h2>
              <h3>a) Percentage Complete</h3>
              <p>Once an activity is added to your timesheet click on the activity a window will open, this will have all
                the details of the activity in it, click on update status .</p><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T60.png'} alt="" width="750" className="pic" />
              </Box><br />
              <p>The box below will open click the dropdown arrow to reveal the % completes </p>
              <Box display="flex" flexDirection={'row'} justifyContent={'center'}>
                <Box>
                  <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T61.png'} alt="" width="350" className="pic" />
                </Box>
                <Box marginLeft='10px'>
                  <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T62.png'} alt="" width="350" className="pic" />
                </Box>
              </Box><br />
              <p>Select % complete & click submit. Exit by clicking the tick at the top of the box </p>
              <p><b>Please note: </b>Once the percentage complete reaches 90% you will be unable to request hours
                via your timesheet you will need to email your request to the EM of the project  </p>
            </GridListTile >

            <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef4 = ref}>
              <h2 >Q4.How to request Additional hours to the Tasks?</h2>
              <p>Click on the timesheet to show the add task box, select the project, delivery schedule,
                WBS & task you require. Select the request hours icon, which is located below select task.
                Additional hours should be requested prior to Friday morning of timesheet submission and always
                before work is carried out. </p><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T63.png'} alt="" width="400" className="pic" />
              </Box><br />
              <p> The window below will open where you can either select to send it your team lead,
                for non project related requests, or straight to the EM, if the request relates to a specific project.
                You must fill in the requested information make a comment in the box & click submit </p>
              <Box display="flex" flexDirection={'row'} justifyContent={'center'}>
                <Box>
                  <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T64.png'} alt="" width="350" className="pic" />
                </Box>
                <Box marginLeft='10px'>
                  <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T65.png'} alt="" width="350" className="pic" />
                </Box>
              </Box><br />
              <p><b>note: </b>This will then be forwarded to the team lead or EM via email for approval.</p>
            </GridListTile >

            <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef5 = ref}>
              <h2 >Q5.How to plan Time for the added Task?</h2>
              <p>Drag down the task added to the appropriate time & day you planned to perform.
                User can manage time with respect to the WBS/Task deadlines allocated to them by their team leads and managers.<br /><br />
                The  <b>“Task successfully added”</b> notification will be shown if the hours are not exceeded to the allocated hours. In case of exceeding the allocated hours, the user will be notified with a message as <b>“Changing to old data!”</b> and <b>“Data not Saved!”</b>.<br /><br />
                Also, an alert message will pop up with the allocated hours and the left hours.
                The user should now contact either the Team Lead or Engineering Manager to request for additional hours.</p><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T10.png'} alt="" width="750" className="pic" />
              </Box><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T11.png'} alt="" width="750" />
              </Box><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T12.png'} alt="" width="750" />
              </Box><br />
            </GridListTile >

            <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef6 = ref}>
              <h2 >Q6.How to add Comments to the Tasks?</h2>
              <p>Click on the blue colour time item to add <b>“Comments”</b> for the tasks performed.
                The Task Info displays the project details ie., Name, Deliverable Schedule, WBS, Task, Start Date, End Date, and the allocated hours.<br /><br />
                To update the status of work based on the task progressed use the <b>“Update Status”</b> option to set percentage complete and Click on Submit.</p><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T13.png'} alt="" width="750" className="pic" />
              </Box><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T14.png'} alt="" width="400" className="pic" />
              </Box><br />
              <p>Delete option will delete the task if it’s not required.<br />
                Split Task Option will help to Split that particular task for multiple days and click Add Task option to add that to the calendar.</p><br /><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T15.png'} alt="" width="400" className="pic" />
              </Box><br />
              <p>Type the detail about the work in the Comments box and Click on <b>“Save & Close”</b> to save the comments.</p><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T16.png'} alt="" width="750" className="pic" />
              </Box><br />
            </GridListTile>

          </GridList>
        </Paper>

      </div>



    )
  }
}

export default withStyles(useStyles)(MyCalendar);
