import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import HelpIcon from '@material-ui/icons/Help';

class HowToGuide extends Component {
    render() {
        return (
            <div>
                <Box display={'flex'} flexDirection={'row'} style={{ height: (window.innerHeight - 100), overflowY: 'scroll', }}>
                    <Box display={'flex'} flexDirection={'column'} m={1}>
                        <Box display="flex" flexDirection={'row'} justifyContent="left" ml={'50px'} mt={'5px'}>
                            <ul>
                                <li>
                                    All PaRaM forms can be found by selecting to icon below, which is in the top right corner of your dashboard. (If you hover over this it will display the name - raise a request.)
                                </li>
                            </ul>
                        </Box>
                        <Box display="flex" flexDirection={'row'} justifyContent="left" ml={'50px'} >
                            <img src={process.env.PUBLIC_URL + '/FormQuide/raise_a_request_icon.PNG'} alt="" width="250" className="pic" />
                        </Box>
                        <Box display="flex" flexDirection={'row'} justifyContent="left" ml={'30px'} mt={'15px'}>
                            There are then 4 options to choose from please see options below:
                        </Box>
                        <Box display="flex" flexDirection={'row'} justifyContent="left" ml={'80px'} mt={'15px'}>
                            <ul>
                                <li>
                                    Setting Up Project
                                </li>
                                <li>
                                    Requirements, Developments & Improvements
                                </li>
                                <li>
                                    Defects/Error
                                </li>
                                <li>
                                    New User Request
                                </li>
                            </ul>
                        </Box>
                        <Box display="flex" flexDirection={'row'} justifyContent="left" ml={'30px'} mt={'15px'}>
                            Select the option you require. Populate each form as indicted with the information required.
                        </Box>
                        <Box display="flex" flexDirection={'row'} justifyContent="left" ml={'30px'} mt={'15px'}>
                            There are also other areas within PaRaM where you can access these forms.
                        </Box>
                        <Box display="flex" flexDirection={'row'} justifyContent="left" ml={'30px'} mt={'15px'}>
                            The forms below can be accessed via the Help and Support, icon
                            <HelpIcon />
                            this is located on the left on your dashboard.
                        </Box>
                        <Box display="flex" flexDirection={'row'} justifyContent="left" ml={'80px'} mt={'15px'}>
                            <ul>
                                <li>
                                    Defects/Error
                                </li>
                                <li>
                                    Requirements, Developments & Improvements
                                </li>
                            </ul>
                        </Box>
                        <Box display="flex" flexDirection={'row'} justifyContent="left" ml={'30px'} mt={'15px'}>
                            Then select the raise a request icon <b>(Raise A Request)</b> which you will find at the top right corner of the page. Select required form & populate.
                        </Box>
                        <Box display="flex" flexDirection={'row'} justifyContent="left" ml={'30px'} mt={'30px'}>
                            <b>All requests will be actioned by PaRaM Administrators, and you will receive notification of when all requests are fulfilled.</b>
                        </Box>
                    </Box>
                </Box>
            </div>
        );
    }
}

export default HowToGuide;