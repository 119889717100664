import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import { GridList, withStyles, GridListTile } from '@material-ui/core';
import Box from '@material-ui/core/Box';
const useStyles = ((theme) => ({
    root: {
        width: '100%',

    },
    root1: {
        minWidth: 275,
        paddingRight: 1150,
        alignItems: 'center',
    },
    paper: {
        height: '100%',
        width: '100%',
        flexShrink: 0,
    },
    title: {
        fontSize: 14
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        color: "black"

    },
}));
class AvailableWorkbook extends Component {
    updateHash(val) {
        window.location.hash = val
    }

    constructor(props) {
        super(props)

        this.state = {

        }
        this.changeContent = this.changeContent.bind(this)
    }
    componentDidMount() {

        // console.log(getCookie("bb_ts_user"))
        // console.log(window.location.hash)
        setTimeout(() => {
            if (window.location.hash === '#q1') {
                this.myRef1.scrollIntoView()
            } else if (window.location.hash === '#q2') {
                this.myRef2.scrollIntoView()
            }
            else if (window.location.hash === '#q3') {
                this.myRef3.scrollIntoView()
            }
            else if (window.location.hash === '#q4') {
                this.myRef4.scrollIntoView()
            }
            else {
                this.myRef.scrollIntoView()
            }
        }, 300);
    }
    changeContent(data) {
        // debugger;
        if (data === 'q1') {
            this.myRef1.scrollIntoView()
        }
        else if (data === 'q2') {
            this.myRef2.scrollIntoView()
        }
        else if (data === 'q3') {
            this.myRef3.scrollIntoView()
        }
        else if (data === 'q4') {
            this.myRef4.scrollIntoView()
        }
        else {
            this.myRef.scrollIntoView()
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root} ref={(ref) => this.myRef = ref}>
                <Paper >
                    <GridList cols={1} cellHeight='auto' >

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef1 = ref}>
                            <h2>Q1.How to update percent complete (%) for a task?</h2>
                            <p><b>Step 1:</b>Browse to “Available Workbook” </p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T25.png'} alt="" width="250" className="pic" />
                            </Box>
                            <p><b>Step 2:</b>Search for your project & Click on the arrow at the left end to see the list of deliverable schedules </p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T26.png'} alt="" width="750" className="pic" />
                            </Box>
                            <p><b>Step 3:</b>Then, open the respective deliverable schedule by clicking on the Actions icon at the left end to launch the list of tasks information page: </p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T27.png'} alt="" width="750" className="pic" />
                            </Box>
                            <p><b>Step 4:</b>Click on the edit icon at the left end, to change the percent complete in the “Complete (%)” column </p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T28.png'} alt="" width="750" className="pic" />
                            </Box>
                            <p><b>Step 5:</b>Set the percent complete, and then tick mark at the left end to save the update made </p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T29.png'} alt="" width="750" className="pic" />
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T30.png'} alt="" width="750" className="pic" />
                            </Box>
                            <p><b>Step 6:</b>Green color message box with confirmation that the data has been successfully updated </p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T31.png'} alt="" width="750" className="pic" />
                            </Box>


                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef2 = ref}>
                            <h2>Q2.How to Update Rate card for Deliverable Schedules in Estimates(Work Winning)?</h2>
                            <p><b>Step 1:</b>For projects already in ‘Stage 5’,the rate card template can be updated to reflect either 2020 rates or 2021 rates as “Default” rate card. </p><br />
                            <p>Open the project,and click on the estimate icon in “Estimates” page under “Work Winning”</p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T32.png'} alt="" width="750" className="pic" />
                            </Box><br />
                            <p>First check the default rate card year (it is Default – 2020 for the below example project). If this rate card & rates for the roles are correct, no further action required.</p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T33.png'} alt="" width="750" className="pic" />
                            </Box><br />
                            <p>From the drop down select the ‘Year’ and click ‘Submit’.This will import the respective years’ rate card template to your deliverable schedule.</p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T34.png'} alt="" width="450" className="pic" />
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T35.png'} alt="" width="450" className="pic" />
                            </Box> <br />
                            <p>The rate card for 2021 is now imported into your schedule.</p>
                            <p>The next step is to set the “Default” rate card for your deliverable schedule. Click on the “Change Default Estimate Rate Card”</p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T36.png'} alt="" width="750" className="pic" />
                            </Box> <br />
                            <p>Select the year, and ‘submit’. In this case, we want to set our rate card to 2021 rate card.  </p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T37.png'} alt="" width="450" className="pic" />
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T38.png'} alt="" width="450" className="pic" />
                            </Box> <br />
                            <p>Once submitted,notice the Default rate card is updated. </p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T39.png'} alt="" width="750" className="pic" />
                            </Box>
                            <p>Now,the calculations for the role amount will be based on the ‘Default’ set rate card for that particular deliverable schedule.</p>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef3 = ref}>
                            <h2>Q3.How to Run PSR (Project Status Report)Report?</h2>
                            <p>Click on “The Eye” icon, and “Request Report”. Note that by default, the report fetch “Live” data.</p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T40.png'} alt="" width="750" className="pic" />
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T41.png'} alt="" width="650" className="pic" />
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T42.png'} alt="" width="750" className="pic" />
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T43.png'} alt="" width="450" className="pic" />
                            </Box>
                            <p>To run a report for any historical date,click on the “Take Snapshot”. Fill the:</p>
                            <p>“Snapshot Name” – any description as it fits for you
                                “Month of the Snapshot” – Select the month, so that its easy for you later when you look for the report</p>
                            <p>“Actual hours upto” – The actual hours in your PSR will be fetched upto this date. Example: For April 2021, we can select 30/04/2021 – usually it’s the last friday of the month. For May 2021, its 28/05/2021.</p>
                            <p>Now the report is saved successfully. But still you will be in the “Live” report page. To load the “PSR for April to Ops” report,</p>
                            <p>Select the dropdown, and select 2021.</p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T44.png'} alt="" width="750" className="pic" />
                            </Box>
                            <p>Then in the next dropdown field you can see all the PSR reports that you took a snapshot in 2021.</p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T45.png'} alt="" width="750" className="pic" />
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T46.png'} alt="" width="750" className="pic" />
                            </Box>
                            <p>Select it to view the report. Likewise, Take a Snapshot, Save your PSR, and browse through between the reports.</p>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T47.png'} alt="" width="750" className="pic" />
                            </Box>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef4 = ref}>
                            <h2>Q4.EVP:Earned Value Performance?</h2>
                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T48.png'} alt="" width="750" className="pic" />
                            </Box>
                            <p>Planned Value (PV) is the budgeted cost for the work scheduled to be done. This is the portion of the project budget planned to be spent at any given point in time.</p>
                            <p>Earned Value (EV) is the percent of the total budget actually completed at a point in time. EV is calculated by multiplying the budget for an activity or work package by the percentage progress.</p>
                            <p><b>EV = % complete x budget</b></p>
                            <p>Actual Costs (AC) is simply the money spent for the work accomplished.</p>
                            <p>Now, we can do some key calculations which provide information on how the project is doing.</p>
                            <p>Schedule Performance Index: <b>SPI = EV / PV</b></p>
                            <p>Cost Performance Index: <b>CPI = EV / AC</b></p>
                            <p>A CPI value of 0.80 implies that for every project pound spent, only £0.80 in earned value was accomplished.
                                A CPI of less than one is below the plan.</p>
                            <p>A SPI value of 1.05 implies that for every pound of work the project had planned to accomplish at this point in time, £1.05 worth of work was actually done.
                                A SPI greater than one indicates more work has been accomplished than was planned.</p>

                            <Box display="flex" justifyContent="center">
                                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T49.png'} alt="" width="750" className="pic" />
                            </Box>
                            <p>The above exhibit shows the Planned Value, Actual Cost and Earned Value for a project.</p>
                            <p>Cost Variance <b>CV = EV – AC</b> </p>
                            <p>ie., <b>CV = £30 – £35 = -£5</b></p>
                            <p>Schedule Variance <b>SV = EV – PV</b> </p>
                            <p>ie., <b>SV = £30 – £50 = -£20</b></p>

                            <p>Note that when the planned spend curve is compared to the actual spent, it shows a variance of +£15 (£50 - £35).
                                An observer is likely to conclude the project team is accomplishing the planned work and doing it for less money.</p>

                            <p>However, analysing the project using earned value gives a different picture. Reading from the graph shows a cost variance of -£5 and a schedule variance of -£20. The project team has accomplished (“Earned”) £30. However, at this point in time, the schedule plan was to accomplish £50 of work. Therefore, the project team is £20 behind in schedule work. In addition, the actual cost for the work accomplished was £35 and the budget for the work accomplished was only £30.
                                This means the project has overspent for the work done. The bottom line Earned Value analysis clearly demonstrated this project is in trouble.</p>

                            <p><b>Using CPI to determine Final Project Cost:</b></p>
                            <p>The Cost Performance Index (CPI) is an excellent indicator of the cost efficiency for completed work.
                                One main use of CPI is forecasting the final project cost.</p>
                            <p>Cost to come (CTC) – the expected additional cost needed to complete the project.</p>
                            <p>Estimate at Completion (EAC) – The expected total cost of the project when the defined scope of work is completed</p>
                            <p>EAC Projected = PV – EV + AC</p>
                            <p>Vs Budget = EAC Projected / PV</p>
                        </GridListTile>
                    </GridList>
                </Paper>

            </div>


        )
    }
}

export default withStyles(useStyles)(AvailableWorkbook);
