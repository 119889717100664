import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { GridList, GridListTile, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ReceiptIcon from '@material-ui/icons/Receipt';

const useStyles = ((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1800,

  },
  // root1: {
  //   minWidth: 275,
  //   paddingRight: 1150,
  //   alignItems: 'center',
  // },
  // paper: {
  //   height: '100%',
  //   width: '100%',
  //   flexShrink: 0,
  // },
  // title: {
  //   fontSize: 14
  // },
  // heading: {
  //   fontSize: theme.typography.pxToRem(15),
  //   fontWeight: theme.typography.fontWeightRegular,
  //   color: "black"
  // },
  listTile: {
    padding: 10,
  }
}));


class MyTimesheet extends Component {

  constructor(props) {
    super(props)

    this.state = {

    }
    this.changeContent = this.changeContent.bind(this)
  }
  updateHash(val) {
    window.location.hash = val
  }

  componentDidMount() {
    setTimeout(() => {
      if (window.location.hash === '#q1') {
        this.myRef1.scrollIntoView()
      } else if (window.location.hash === '#q2') {
        this.myRef2.scrollIntoView()
      } else if (window.location.hash === '#q3') {
        this.myRef3.scrollIntoView()
      } else if (window.location.hash === '#q4') {
        this.myRef4.scrollIntoView()
      } else {
        this.myRef.scrollIntoView()
      }
    }, 300);
  }

  changeContent(data) {
    if (data === 'q1') {
      this.myRef1.scrollIntoView()
    } else if (data === 'q2') {
      this.myRef2.scrollIntoView()
    } else if (data === 'q3') {
      this.myRef3.scrollIntoView()
    } else if (data === 'q4') {
      this.myRef4.scrollIntoView()
    } else {
      this.myRef.scrollIntoView()
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} ref={(ref) => this.myRef = ref}>
        <Paper >
          <GridList cols={1} cellHeight='auto' >

            <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef1 = ref}>
              <h2 >Q1.How  to Submit my Timesheet?</h2>
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T1.png'} alt="" width="100%" className="pic" />
              </Box><br />
              <p>Click on the icon  <b>“My calendar”</b> on the top left to initiate timesheet submission.</p><br /><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T18.png'} alt="" width="750" className="pic" />
              </Box><br />
              <p>The timesheet will be displayed with the details about the Tasks overview like Projects, Tasks, Comments, Hours Expended, and the utilised hours allocated by the user.
                If the list of tasks is more than 10 rows use rows drop down to increase the number of rows.
                The total hours utilised will be listed at the bottom and use Submit Timesheet option to submit it to the concern manager.</p><br /><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T19.png'} alt="" width="400" className="pic" />
              </Box><br />
              <p>Ensure to enter the comments before Submitting the timesheet.</p><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T20.png'} alt="" width="400" className="pic" />
              </Box><br />
              <p>If the total allocated hours are not enrolled, then an alert message will be popped up and the timesheet cannot be submitted until all fields are filled.<br /><br />
                If submitted successfully <b>“Timesheet Submitted”</b> will be shown near the calendar option.</p><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T21.png'} alt="" width="750" className="pic" />
              </Box><br />
            </GridListTile>

            <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef2 = ref}>
              <h2>Q2.How do I know if the Timesheet is submitted?</h2>
              <p>The Submitted timesheet will be displayed in orange colour and that denotes that the timesheet has been sent for approval by the team lead or manager.</p><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T22.png'} alt="" width="750" className="pic" />
              </Box><br />
            </GridListTile>

            <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef3 = ref}>
              <h2 >Q3.How will be the calendar once it’s approved?</h2>
              <p>There will be an automated mail received to the user stating that Timesheet has been <b>“approved/rejected”</b> by the concern team leader or the manager.</p><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T23.png'} alt="" width="750" className="pic" />
              </Box><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T24.png'} alt="" width="750" className="pic" />
              </Box><br />
              <p>The approved timesheet will later turn into green colour to denote that it’s been approved by the manager</p>
            </GridListTile>

            <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef4 = ref}>
              <h2 >Q4.Timesheet Approval Process?</h2>
              <p>Timesheets are sent for approval via email, to approve a timesheet via email,
                open the email and there will be a timesheet as per the screen shot below. When approving a
                timesheet you need to ensure that there is a comment in the box to state clearly what the requestor
                has spent their time on. Any project work in ‘non billable’ should be rejected and resubmitted to
                the correct project code. Review & click appropriate icon. </p><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T66.png'} alt="" width="750" className="pic" />
              </Box><br />
              <p>Another email will be sent to let you know if your timesheet has been approved or not as per screen shot below </p>
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T67.png'} alt="" width="750" className="pic" />
              </Box><br />
              <p>If you are an approver of timesheets you can check to see if all your timesheets have been approved by clicking on the
                Your Portfolio icon <ReceiptIcon color='primary'/> on your dashboard, the below window opens, if the timesheet is not approved click on the
                icon on the right of the person’s name to approve. </p>
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T68.png'} alt="" width="750" className="pic" />
              </Box>
            </GridListTile>

          </GridList>
        </Paper>

      </div>

    )
  }
}

export default withStyles(useStyles)(MyTimesheet)
