import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormQues from './FormQues';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core';

const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

class PaRaMForms extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <AppBar position='fixed'>
                    <Toolbar variant="h2">
                        <IconButton onClick={() => window.close()} edge="start" className={classes.menuButton} color="inherit">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap className={classes.title}>
                            PARAM FORMS FAQ's
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box mt={'90px'}>
                    <FormQues />
                </Box>
            </div>
        );
    }
}

export default withStyles(useStyles)(PaRaMForms);