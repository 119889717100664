import React, { Component } from 'react'
import MyCalendar from '../Contents/MyCalendar/MyCalendar'
import MyTimesheet from '../Contents/MyTimesheet/MyTimesheet'
import Box from '@material-ui/core/Box';
import General from '../Contents/General/General';
import AvailableWorkbook from '../Contents/Available Workbook/AvailableWorkbook';
import PaRaMFormsFaqs from '../Contents/PaRaMFormsFaqs/PaRaMFormsFaqs';

class ContentView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewClass: '',
        }
        this.changeView = this.changeView.bind(this)
        this.changeContent = this.changeContent.bind(this)
        this.contentView = React.createRef();
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({
                viewClass: this.props.value.toLowerCase()
            })
        }

    }

    changeView(val) {
        console.log(val)
        this.setState({
            viewClass: val
        })
    }

    changeContent(data) {
        this.contentView.current.changeContent(data)
    }

    render() {
        return (
            <Box>
                {
                    (this.state.viewClass === "general") ? (<General ref={this.contentView} />) :
                        (this.state.viewClass === "mycalendar") ? (<MyCalendar ref={this.contentView} />) :
                            (this.state.viewClass === "mytimesheet") ? (<MyTimesheet ref={this.contentView} />) :
                                (this.state.viewClass === "availableworkbook") ? (<AvailableWorkbook ref={this.contentView} />) :
                                    (this.state.viewClass === "paramforms") ? (<PaRaMFormsFaqs ref={this.contentView} />) : (null)
                }
            </Box>
        )
    }
}

export default ContentView