import React, { Component } from 'react';
import { fade, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Avatar, Box, Tooltip } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getCookie } from './CookieHelper';

const useStyles = (theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appbarimg: {
    height: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
  },
  orange: {
    backgroundColor: 'orange'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    padding: theme.spacing(0, 2, 0, 0),
    width: '100%',
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      user: null,
    }
  }

  handleAvatarClick = (event) => {
    window.open("https://bbparam.com")
  };

  onSearch = (event, values) => {
    if (values && values !== '' && this.props.value !== values.category) {
      window.location.replace("/paramfaqs/" + values.category + values.hash)
    } else if (values && values !== '') {
      window.location.replace("/paramfaqs/" + values.category + values.hash)
      window.location.reload()
    }
  }

  componentDidMount() {

    var url = window.location.protocol + '//' + window.location.hostname

    if (!window.location.port || window.location.port === '') {
      url = url + "/searchdata/search.json"
    } else {
      url = url + ":" + window.location.port + "/searchdata/search.json"
    }

    fetch(url)
      .then(res => res.json())
      .then(data => {
        this.setState({
          data: data
        })
      })

    if (getCookie("bb_ts_user") && window.location.host === "faqs.bbparam.com") {
      this.setState({
        user: JSON.parse(getCookie("bb_ts_user"))
      })
    }
  }

  render() {

    const { classes } = this.props;

    return (

      <div className={classes.root}>

        <AppBar position="fixed">
          <Toolbar variant="h2">
            <Typography variant="h6" noWrap className={classes.title}>
              PARAM FAQ's
            </Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>

              <Autocomplete
                id="combo-box-demo"
                options={this.state.data}
                getOptionLabel={(option) => option.title}
                style={{ width: 300 }}
                onChange={this.onSearch}
                renderInput={(params) => <InputBase
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  placeholder="Search..." variant="outlined"
                />}
              />
            </div>

            <Tooltip title="Profile">
              <Avatar style={{ marginRight: '0px' }} onClick={e => {
                if (getCookie("bb_ts_user") && window.location.host === "faqs.bbparam.com") {
                  alert("Login Information:\nLogin Provider: BB PaRaM\nUser: " + this.state.user.last_name + ', ' + this.state.user.first_name + "\nEmail: " + this.state.user.email + "\nWeb: https:bbparam.com")
                }
              }} className={classes.orange}><Box fontSize={16} fontWeight="fontWeightBold">{(window.location.host === 'faqs.bbparam.com' && this.state.user) ? (this.state.user.last_name.charAt(0) + this.state.user.first_name.charAt(0)) : (null)}</Box>
              </Avatar>
            </Tooltip>
          </Toolbar>
        </AppBar>

      </div >
    );
  }
}
export default withStyles(useStyles)(Header)