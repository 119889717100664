import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { GridList, GridListTile, withStyles } from '@material-ui/core';

const useStyles = ((theme) => ({
    root: {
        width: '100%',
        maxWidth: 1800,

    },
    listTile: {
        padding: 10,
    }
}));


class PaRaMFormsFaqs extends Component {

    constructor(props) {
        super(props)

        this.state = {

        }
        this.changeContent = this.changeContent.bind(this)
    }
    updateHash(val) {
        window.location.hash = val
    }

    componentDidMount() {
        setTimeout(() => {
            if (window.location.hash === '#q1') {
                this.myRef1.scrollIntoView()
            } else if (window.location.hash === '#q2') {
                this.myRef2.scrollIntoView()
            } else if (window.location.hash === '#q3') {
                this.myRef3.scrollIntoView()
            } else if (window.location.hash === '#q4') {
                this.myRef4.scrollIntoView()
            } else if (window.location.hash === '#q5') {
                this.myRef5.scrollIntoView()
            } else if (window.location.hash === '#q6') {
                this.myRef6.scrollIntoView()
            } else if (window.location.hash === '#q7') {
                this.myRef7.scrollIntoView()
            } else if (window.location.hash === '#q8') {
                this.myRef8.scrollIntoView()
            } else if (window.location.hash === '#q9') {
                this.myRef9.scrollIntoView()
            } else if (window.location.hash === '#q10') {
                this.myRef10.scrollIntoView()
            } else if (window.location.hash === '#q11') {
                this.myRef11.scrollIntoView()
            } else if (window.location.hash === '#q12') {
                this.myRef12.scrollIntoView()
            } else if (window.location.hash === '#q13') {
                this.myRef13.scrollIntoView()
            } else if (window.location.hash === '#q14') {
                this.myRef14.scrollIntoView()
            } else {
                this.myRef.scrollIntoView()
            }
        }, 300);
    }

    changeContent(data) {
        if (data === 'q1') {
            this.myRef1.scrollIntoView()
        } else if (data === 'q2') {
            this.myRef2.scrollIntoView()
        } else if (data === 'q3') {
            this.myRef3.scrollIntoView()
        } else if (data === 'q4') {
            this.myRef4.scrollIntoView()
        } else if (data === 'q5') {
            this.myRef5.scrollIntoView()
        } else if (data === 'q6') {
            this.myRef6.scrollIntoView()
        } else if (data === 'q7') {
            this.myRef7.scrollIntoView()
        } else if (data === 'q8') {
            this.myRef8.scrollIntoView()
        } else if (data === 'q9') {
            this.myRef9.scrollIntoView()
        } else if (data === 'q10') {
            this.myRef10.scrollIntoView()
        } else if (data === 'q11') {
            this.myRef11.scrollIntoView()
        } else if (data === 'q12') {
            this.myRef12.scrollIntoView()
        } else if (data === 'q13') {
            this.myRef13.scrollIntoView()
        } else if (data === 'q14') {
            this.myRef14.scrollIntoView()
        } else {
            this.myRef.scrollIntoView()
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root} ref={(ref) => this.myRef = ref}>
                <Paper >
                    <GridList cols={1} cellHeight='auto' >

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef1 = ref}>
                            <h2>Q1. Where can I find the Forms?</h2>
                            <p>All forms are stored on your dashboard under Raise a Request icon.</p>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef2 = ref}>
                            <h2 >Q2. Who can submit PaRaM Forms?</h2>
                            <p> Defects and requirements forms can be submitted by all PaRaM Users, however New User Request forms and
                                setting up a project can only be requested by Engineering Managers, Head of Engineering,
                                Design delivery Managers and Team Leads.</p>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef3 = ref}>
                            <h2>Q3. How long does it take to process a Form by administrators after submission?</h2>
                            <p> <ul>
                                <li>
                                    Our service level agreement is 2 days to turn around request.
                                </li>
                                <li>
                                    Your request will be acknowledged in 5 working days after it’s been reviewed in our PaRaM Governance Board.
                                </li>
                            </ul></p>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef4 = ref}>
                            <h2 >Q4. Where can I view my submitted requests?</h2>
                            <p> Your pending requests for the defects and requirements forms can be viewed in Help and Support section.</p>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef5 = ref}>
                            <h2>Q5. Who will approve the Forms?</h2>
                            <p>The forms will get approved by PaRaM Governance and Process Board.</p>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef6 = ref}>
                            <h2 >Q6. Do I get an email notification?</h2>
                            <p> Yes.</p>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef7 = ref}>
                            <h2>Q7. Can I edit/update my Form after submission?</h2>
                            <p> Yes, you can edit your form after submission via the Support Manager icon
                                which can be found on your dashboard from the menu on the left.</p>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef8 = ref}>
                            <h2>Q8. Can I delete a form or recover a deleted Form?</h2>
                            <p> Unfortunately, the form cannot be deleted before or after the submission.</p>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef9 = ref}>
                            <h2>Q9. How many Forms can I create?</h2>
                            <p>  There is no limit.</p>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef10 = ref}>
                            <h2>Q10. Can I add multiple people on one Form?</h2>
                            <p> No, you can add only one submission at a time.</p>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef11 = ref}>
                            <h2>Q11. What file types can you upload on the Form?</h2>
                            <p> You can upload all file types.</p>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef12 = ref}>
                            <h2>Q12. What is the difference between Internal Emp ID and R12 Emp Code?</h2>
                            <p>Internal Emp ID is only used in India and R12 Emp Code is used in UK.</p>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef13 = ref}>
                            <h2>Q13. What is the character limit for Forms?</h2>
                            <p> The character limit for comments on the forms will vary from section to section.</p>
                        </GridListTile>

                        <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef14 = ref}>
                            <h2>Q14. Is there a file number limit in Drag & Drop section?</h2>
                            <p> The limit of files that you can upload is <b>25MB</b></p>
                        </GridListTile>

                    </GridList>
                </Paper>

            </div>

        )
    }
}

export default withStyles(useStyles)(PaRaMFormsFaqs)
