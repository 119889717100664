import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import { GridList, withStyles, GridListTile } from '@material-ui/core';
import Box from '@material-ui/core/Box';
// import { getCookie } from '../../CommonUtils/CookieHelper';

const useStyles = ((theme) => ({
  root: {
    width: '100%',

  },
  root1: {
    minWidth: 275,
    paddingRight: 1150,
    alignItems: 'center',
  },
  paper: {
    height: '100%',
    width: '100%',
    flexShrink: 0,
  },
  title: {
    fontSize: 14
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "black"

  },
}));


class General extends Component {
  updateHash(val) {
    window.location.hash = val
  }

  constructor(props) {
    super(props)

    this.state = {

    }
    this.changeContent = this.changeContent.bind(this)
  }


  componentDidMount() {

    // console.log(getCookie("bb_ts_user"))
    // console.log(window.location.hash)
    setTimeout(() => {
      if (window.location.hash === '#q1') {
        this.myRef1.scrollIntoView()
      } else if (window.location.hash === '#q2') {
        this.myRef2.scrollIntoView()
      } else {
        this.myRef.scrollIntoView()
      }
    }, 300);

  }

  changeContent(data) {
    if (data === 'q1') {
      this.myRef1.scrollIntoView()
    } else if (data === 'q2') {
      this.myRef2.scrollIntoView()
    } else {
      this.myRef.scrollIntoView()
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} ref={(ref) => this.myRef = ref}>
        <Paper >
          <GridList cols={1} cellHeight='auto' >

            <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef1 = ref}>
              <h2>Q1.How to log into Param Portal?</h2>
              <p>Open <b>"Microsoft Edge"</b> or <b>"Google Chrome"</b> and type <a href="http://bbparam.com"><b>"http://bbparam.com"</b></a>  in the address bar or click the link sent via mail.<br /><br />
      If connected to the BB network or Global Protect, it will be auto-logged-in, otherwise, the user can use Microsoft office365 Authentication credentials to verify account to log in successfully.<br /> <br />
      It will land on the PaRaM landing page - Teams Dashboard as shown below.</p>
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T1.png'} alt="" width="100%" className="pic" />
              </Box>
            </GridListTile>

            <GridListTile style={{ padding: '15px' }} ref={(ref) => this.myRef2 = ref}>
              <h2>Q2.What are the Default options available in Param?</h2>
              <p>The page is listed with <b>My Calendar,Workload Report,My Timesheets,Submit Timesheet & Help and Support options by default.</b></p><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T2.png'} alt="" height="250" className="pic" />
              </Box><br />
              <p>Hover the mouse over the icon for a tool tip or click the three bars over the top left corner to get a brief note of the menu bar.</p><br />
              <Box display="flex" justifyContent="center">
                <img src={process.env.PUBLIC_URL + '/images/Timesheetimages/T3.png'} alt="" width="100%" className="pic" />
              </Box>
            </GridListTile>


          </GridList>
        </Paper>

      </div>


    )
  }
}

export default withStyles(useStyles)(General);
