import React, { Component } from 'react'
import MyCalendarIndex from '../Contents/MyCalendar/MyCalendarIndex'
import Box from '@material-ui/core/Box';
import MyTimesheetIndex from '../Contents/MyTimesheet/MyTimesheetIndex';
import GeneralIndex from '../Contents/General/GeneralIndex';
import AvailableWorkbookIndex from '../Contents/Available Workbook/AvailableWorkbookIndex';
import PaRaMFormsFaqsIndex from '../Contents/PaRaMFormsFaqs/PaRaMFormsFaqsIndex';

class IndexView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewClass: 'General',
        }
        this.changeIndex = this.changeIndex.bind(this)
        this.changeContent = this.changeContent.bind(this)
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({
                viewClass: this.props.value.toLowerCase()
            })
        }

    }

    changeIndex(val) {
        console.log(val)
        this.setState({
            viewClass: val
        })
    }

    changeContent(data) {
        this.props.onContentChange(data)
    }

    render() {
        return (
            <Box display='flex'>
                {
                    (this.state.viewClass === "general") ? (<GeneralIndex onContentChange={this.changeContent} />) :
                        (this.state.viewClass === "mycalendar") ? (<MyCalendarIndex onContentChange={this.changeContent} />) :
                            (this.state.viewClass === "mytimesheet") ? (<MyTimesheetIndex onContentChange={this.changeContent} />) :
                                (this.state.viewClass === "availableworkbook") ? (<AvailableWorkbookIndex onContentChange={this.changeContent} />) :
                                    (this.state.viewClass === "paramforms") ? (<PaRaMFormsFaqsIndex onContentChange={this.changeContent} />) : (null)
                }
            </Box>
        )
    }
}

export default IndexView