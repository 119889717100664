import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { List } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

class AvailableWorkbookIndex extends Component {

    constructor(props) {
        super(props)

        this.state = {

        }
        this.contentUpdate = this.contentUpdate.bind(this)
    }


    updateHash(val) {
        window.location.hash = val
    }


    contentUpdate(data) {
        this.props.onContentChange(data)
    }

    render() {

        return (

            <Paper>
                <div style={{ width: '100%', padding: '15px' }}>
                    <b>Table of Contents</b>
                    <div>
                        <List>

                            <ListItem button onClick={() => { this.contentUpdate('q1'); this.updateHash('q1') }}>
                                <ListItemText primary="1.How to update percent complete (%) for a task?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q2'); this.updateHash('q2') }}>
                                <ListItemText primary="2.How to Update Rate card for Deliverable Schedules in Estimates(Work Winning)?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q3'); this.updateHash('q3') }}>
                                <ListItemText primary="3.How to Run PSR (Project Status Report)Report?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q4'); this.updateHash('q4') }}>
                                <ListItemText primary="4.EVP:Earned Value Performance?" />
                            </ListItem>
                        </List>
                    </div>
                </div>
            </Paper>




        )
    }
}
export default AvailableWorkbookIndex