import React, { Component } from 'react';
import { Box, withStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = ((theme) => ({
    root: {
        width: '100%',
    },
    root1: {
        minWidth: 275,
        paddingRight: 1150,
        alignItems: 'center',
    },
    title: {
        fontSize: 14
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        color: "black"
    },
}));

const StyledListItem = withStyles({
    root: {
        "&.Mui-selected": {
            backgroundColor: "#CFE9F8"
        }
    }
})(ListItem);

class Category extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewClass: 'general',
            list: [
                {
                    name: 'General',
                    id: 'general'
                },
                {
                    name: 'My Calendar',
                    id: 'mycalendar'
                },
                {
                    name: 'My Timesheet',
                    id: 'mytimesheet'
                },
                {
                    name: 'Available Workbook',
                    id: 'availableworkbook'
                },
                {
                    name: 'PaRaM Forms',
                    id: 'paramforms'
                }
            ],
        }
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({ viewClass: this.props.value })
        }

    }

    render() {

        const handleViewChange = (val) => {
            window.history.pushState(null, null, '/paramfaqs/' + val);
            this.setState({ viewClass: val })
            this.props.onViewClass(val)


        }
        return (
            <div style={{ width: '100%' }}>
                <Box display="flex" >
                    <List style={{ width: '100%' }}>
                        {this.state.list.map((text) => (
                            <StyledListItem selected={this.state.viewClass === text.id} button key={text.id} onClick={e => handleViewChange(text.id)}>
                                <ListItemText primary={text.name} />
                            </StyledListItem>
                        ))}
                    </List>
                </Box>

            </div>

        );
    }
}
export default withStyles(useStyles)(Category)