import React, { Component } from 'react'
import Box from '@material-ui/core/Box';
import { Avatar, fade, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getCookie } from '../CommonUtils/CookieHelper';
import { Tooltip } from "@material-ui/core";


const useStyles = ((theme) => ({
  root: {
    flexGrow: 1,
  },


  search: {
    position: 'absolute',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.90),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.45),
    },

  },

  orange: {
    backgroundColor: '#007681'
  },

}));


class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewClass: '',
      data: [],
      user: null
    }
  }
  handleViewChange = (val) => {

    window.location.href = '/paramfaqs/general'
  }

  onSearch = (event, values) => {
    if (values && values !== '') {
      window.open("/paramfaqs/" + values.category + values.hash);
    }

  }
  componentDidMount() {

    if (getCookie("bb_ts_user") && window.location.host === "faqs.bbparam.com") {
      this.setState({
        user: JSON.parse(getCookie("bb_ts_user"))
      })
    }

    var url = window.location.protocol + '//' + window.location.hostname

    if (!window.location.port || window.location.port === '') {
      url = url + "/searchdata/search.json"

    } else {
      url = url + ":" + window.location.port + "/searchdata/search.json"
    }

    fetch(url)
      .then(res => res.json())
      .then(data => {
        console.log(data)
        this.setState({
          data: data
        })
      })
  }

  render() {

    const { classes } = this.props;

    return (
      <div style={{
        backgroundColor: '#ffffff',
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}>

        <div style={{
          backgroundColor: '#ffffff',
          position: 'absolute',
          top: '0px',
          right: '0px',
          padding: '0px',
        }}>
          <Box display="flex" flexDirection="row-reverse" p={1} m={1} >
            <Tooltip title="Profile">
              <Avatar style={{ marginRight: '0px' }} onClick={e => {
                if (getCookie("bb_ts_user") && window.location.host === "faqs.bbparam.com") {
                  alert("Login Information:\nLogin Provider: BB PaRaM\nUser: " + this.state.user.last_name + ', ' + this.state.user.first_name + "\nEmail: " + this.state.user.email + "\nWeb: https:bbparam.com")
                }
              }} className={classes.orange}><Box fontSize={16} fontWeight="fontWeightBold">{(window.location.host === 'faqs.bbparam.com' && this.state.user) ? (this.state.user.last_name.charAt(0) + this.state.user.first_name.charAt(0)) : (null)}</Box>
              </Avatar>
            </Tooltip>
            <Button style={{ marginRight: '10px' }} variant="contained" color="primary" onClick={e => this.handleViewChange('paramfaqs')}>Goto Param Faq's</Button>
          </Box>
        </div>

        <div style={{
          backgroundColor: '#ffffff',
          position: 'absolute',
          top: 0,
          width: '100%',
          marginTop: '10%'
        }}>
          <Box flexDirection="row" maxHeight={300} display="flex" justifyContent="center">
            <img src={"https://flipbook.bbedcindia.com/bbimages/BB_RGB_8.5mm.png"} alt="" width="400" className="pic" />
          </Box>

          <Box flexDirection="row" maxHeight={300} display="flex" justifyContent="center">
            <Autocomplete
              id="combo-box-demo"
              options={this.state.data}
              getOptionLabel={(option) => option.title}
              style={{ width: 550 }}
              onChange={this.onSearch}
              renderInput={(params) => <TextField {...params} label="Search questions" variant="outlined" />} />
          </Box>
        </div>

        <div style={{
          backgroundColor: '#ffffff',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          marginBottom: '0.5%'
        }}>
          <Box fontWeight="fontWeightLight" flexDirection="row" maxHeight={100} display="flex" justifyContent="center">
            Powered by Engineering Design Center
          </Box>
        </div>

      </div>
    )
  }
}
export default withStyles(useStyles)(Search)

