import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { List } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

class PaRaMFormsFaqsIndex extends Component {

    updateHash(val) {
        window.location.hash = val
    }

    contentUpdate(data) {
        this.props.onContentChange(data)
    }

    render() {

        return (

            <Paper>
                <div style={{ width: '100%', padding: '15px' }}>
                    <b>Table of Contents</b>
                    <div>
                        <List>

                            <ListItem button onClick={() => { this.contentUpdate('q1'); this.updateHash('q1') }}>
                                <ListItemText primary="1.Where can I find the Forms?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q2'); this.updateHash('q2') }}>
                                <ListItemText primary="2.Who can submit PaRaM Forms?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q3'); this.updateHash('q3') }} >
                                <ListItemText primary="3.How long does it take to process a Form by administrators after submission?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q4'); this.updateHash('q4') }} >
                                <ListItemText primary="4.Where can I view my submitted requests?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q5'); this.updateHash('q5') }}>
                                <ListItemText primary="5.Who will approve the Forms?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q6'); this.updateHash('q6') }}>
                                <ListItemText primary="6.Do I get an email notification?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q7'); this.updateHash('q7') }}>
                                <ListItemText primary="7.Can I edit/update my Form after submission?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q8'); this.updateHash('q8') }}>
                                <ListItemText primary="8.Can I delete a form or recover a deleted Form?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q9'); this.updateHash('q9') }}>
                                <ListItemText primary="9.How many Forms can I create?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q10'); this.updateHash('q10') }}>
                                <ListItemText primary="10.Can I add multiple people on one Form?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q11'); this.updateHash('q11') }}>
                                <ListItemText primary="11.What file types can you upload on the Form?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q12'); this.updateHash('q12') }}>
                                <ListItemText primary="12.What is the difference between Internal Emp ID and R12 Emp Code?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q13'); this.updateHash('q13') }}>
                                <ListItemText primary="13.What is the character limit for Forms?" />
                            </ListItem>
                            <ListItem button onClick={() => { this.contentUpdate('q14'); this.updateHash('q14') }}>
                                <ListItemText primary="14.Is there a file number limit in Drag & Drop section?" />
                            </ListItem>

                        </List>
                    </div>
                </div>
            </Paper>

        )
    }
}

export default (PaRaMFormsFaqsIndex)
