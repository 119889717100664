import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import Category from '../Category/Category'
import Header from '../CommonUtils/Header'
import ContentView from '../CommonUtils/ContentView'
import IndexView from '../CommonUtils/IndexView'


const useStyles = ((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
export class Main extends Component {
  constructor(props) {
    super(props);
    this.state = { value: 'general' }
    this.handleContent = this.handleContent.bind(this)
    this.changeContent = this.changeContent.bind(this)
    this.contentView = React.createRef();
    this.indexView = React.createRef();
  }


  handleContent(ContentView) {
    this.setState({ value: ContentView })
    this.contentView.current.changeView(ContentView)
    this.indexView.current.changeIndex(ContentView)
    window.scrollTo(0, 0)
  }

  changeContent(data) {
    this.contentView.current.changeContent(data)
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Header className="flex-no-shrink" value={this.props.match.params.category} />
        <Grid container spacing={2} className="flex-section" style={{ marginTop: '90px', marginLeft: '3px', width: "99.5%" }} >
          <Grid
            item
            xs={2}
            className="flex-col-scroll">
            <Paper className={classes.paper}><Category value={this.props.match.params.category} onViewClass={this.handleContent} /></Paper>
          </Grid>
          <Grid
            item
            xs={7}
            className="flex-col-scroll" >
            <ContentView ref={this.contentView} value={this.props.match.params.category} />
          </Grid>
          <Grid
            item
            xs={3}
            className="flex-col-scroll">
            <IndexView ref={this.indexView} value={this.props.match.params.category} onContentChange={this.changeContent} />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default withStyles(useStyles)(Main)
